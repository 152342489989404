// AlertBanner.js
import React from 'react';
import Alert from "@cloudscape-design/components/alert";
import { useTranslation } from 'react-i18next';

export const AlertBanner = () => {
  const { t } = useTranslation();

  return (
    <Alert
      statusIconAriaLabel="Info"
      header={t('alert.aiDisclaimer')}
      dismissible={false}      
    >
        {t('alert.aiDisclaimerText')}
    </Alert>
  );
};