import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@cloudscape-design/components';
import { useTranslation } from 'react-i18next';
import Icon from "@cloudscape-design/components/icon";
import * as api from "../api/api";
import { useAlert } from '../components/Alert';

export const DashboardMetrics = ({data}) => {
  const { t } = useTranslation();
  const [metrics, setMetrics] = useState({
    totalCalls: 0,
    failedCalls: 0,
    totalCost: 0,
    costPerCall: 0
  });
  const { showSuccess, showError } = useAlert();

  const [loading, setLoading] = useState(true);
  const loadingRef = useRef(true);
  const successShownRef = useRef(false);

  useEffect(() => {
    fetchMetricsData();
  }, [data]);

  useEffect(() => {
  // Solo mostrar el éxito una vez cuando loading cambia de true a false
  // y evitar mostrar en la carga inicial o renderizados posteriores
  if (loadingRef.current === true && loading === false && !successShownRef.current) {
    showSuccess(
      t('notifications.dataLoaded', 'Datos cargados correctamente!'),
      t('notifications.success', 'Éxito')
    );
    successShownRef.current = true;
  }
  
  loadingRef.current = loading;
}, [loading]);

  const fetchMetricsData = async () => {
    try {
      setLoading(true);
      successShownRef.current = false;

      const callsData = await api.getCalls();
      
      const totalCalls = callsData.totalCount || 0;
      const failedCalls = callsData.rejectedCount || 0;
      
      
      const totalMinutes = data.Records.reduce((sum, call) => {
        return sum + (call.duration ? call.duration / 60 : 0);
      }, 0);
      
      const ratePerMinute = 0.015;
      const totalCost = totalMinutes * ratePerMinute;
      const costPerCall = totalCalls > 0 ? totalCost / totalCalls : 0;
      
      setMetrics({
        totalCalls,
        failedCalls,
        totalCost,
        costPerCall
      });
    } catch (error) {
      console.error("Error fetching metrics data:", error);
      showError(
        t('notifications.errorLoading', 'Error al cargar los datos. Intente de nuevo.'),
        t('notifications.error', 'Error')
      );
    } finally {
      setLoading(false);
    }
  };

  const metricCards = [
    {
      id: 'totalCalls',
      title: t('dashboardMetrics.totalCalls', 'Total Calls'),
      value: metrics.totalCalls,
      icon: 'call',
      secondaryIcon: 'refresh'
    },
    {
      id: 'failedCalls',
      title: t('dashboardMetrics.failedCalls', 'Failed Calls'),
      value: metrics.failedCalls,
      icon: 'status-negative',
      secondaryIcon: 'close'
    },
    {
      id: 'totalCost',
      title: t('dashboardMetrics.totalCost', 'Total Cost'),
      value: `$${metrics.totalCost.toFixed(2)}`,
      icon: 'information'
    },
    {
      id: 'costPerCall',
      title: t('dashboardMetrics.costPerCall', 'Cost Per Call'),
      value: `$${metrics.costPerCall.toFixed(4)}`,
      icon: 'information'
    }
  ];

  return (
    <Box margin={{ vertical: 'l' }} padding={{ horizontal: 'l' }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '20px' 
      }}>
        {metricCards.map((card) => (
          <MetricCard
            key={card.id}
            title={card.title}
            value={card.value}
            icon={card.icon}
            secondaryIcon={card.secondaryIcon}
            loading={loading}
          />
        ))}
      </div>
    </Box>
  );
};

const MetricCard = ({ title, value, icon, secondaryIcon, loading }) => {
  return (
    <div style={{ 
      background: 'white',
      borderRadius: '12px',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
      padding: '16px 24px',
      minWidth: '200px',
      flex: '1 1 0',
      maxWidth: '260px',
      transform: 'translateY(0)',
      transition: 'transform 0.2s, box-shadow 0.2s',
      cursor: 'default',
      '&:hover': {
        boxShadow: '0 12px 20px rgba(0, 0, 0, 0.15), 0 6px 10px rgba(0, 0, 0, 0.12)',
        transform: 'translateY(-3px)'
      }
    }}>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        marginBottom: '12px' 
      }}>
        <Icon name={icon} />
        {secondaryIcon && (
          <Icon name={secondaryIcon} style={{ marginLeft: '4px' }} />
        )}
        <span style={{ 
          marginLeft: '8px', 
          fontSize: '14px',
          fontWeight: '500' 
        }}>
          {title}
        </span>
      </div>
      <div style={{ 
        fontSize: '28px', 
        fontWeight: 'bold' 
      }}>
        {loading ? "Loading..." : value}
      </div>
    </div>
  );
};

export default DashboardMetrics;