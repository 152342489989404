import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="sticky-footer">
      <p className="footer-text">© {t("footer.reservedRights")}</p>
    </footer>
  );
}

export default Footer;