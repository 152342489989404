import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
// import { Navbar, Nav, Container, Alert, Button } from "react-bootstrap";
import { AppLayout,Alert, BreadcrumbGroup, TopNavigation} from "@cloudscape-design/components"
import Home from "./routes/Home";
import Search from "./routes/Search";
import Dashboard from "./routes/Dashboard/index";
import CallAnalytics from "./routes/CallAnalytics";
import { useState, useEffect } from "react";
import { payloadFromToken, logOut } from "./api/auth";
import { useTranslation } from 'react-i18next';
import "./locales/i18n";
import logo from './assets/logo.png';
import Footer from "./components/Footer";
import Users from "./routes/Users";
import Prompts from "./routes/Prompts";
import PromptEditor from "./routes/PromptEditor";
import Insights from "./routes/Insights";
import { ProtectedRoute } from './components/ProtectedRoute';
import AccessDenied from "./components/AccessDenied";
import { useUserRole } from "./api/role";
import { AlertProvider, useAlert } from './components/Alert';
import { UploadProgressProvider } from './hooks/UploadProgressContext';

const routes = [
  // {
  //   path: "/analytics",
  //   name: "Analytics",
  //   Component: CallAnalytics,
  //   Breadcrumb: () => {
  //     const { t } = useTranslation();
  //     return <BreadcrumbGroup
  //       items={[
  //         { text: t("home.title"), href: "../" },
  //         { text: t("analytics.header"), href: "#" }
  //       ]}
  //       ariaLabel="Breadcrumbs"
  //     />
  //   }
  // },
  {
    path: "/search",
    name: "Search",
    Component: Search,
    Breadcrumb: () => {
      const { t } = useTranslation();
      return <BreadcrumbGroup
        items={[
          { text: t("home.title"), href: "../" },
          { text: t("utilities.search"), href: "search" }
        ]}
        ariaLabel="Breadcrumbs"
      />
    }
  },
  {
    path: "/users",
    name: "Users",
    Component: Users,
    Breadcrumb: () => {
      const { t } = useTranslation();
      return <BreadcrumbGroup
        items={[
          { text: t("home.title"), href: "../" },
          { text: t("utilities.users"), href: "users" }
        ]}
        ariaLabel="Breadcrumbs"
      />
    },
    permission: "manage_users"
  },
  {
    path: "/prompts",
    name: "Prompts",
    Component: Prompts,
    Breadcrumb: () => {
      const { t } = useTranslation();
      return <BreadcrumbGroup
        items={[
          { text: t("home.title"), href: "../" },
          { text: t("utilities.prompts"), href: "prompts" }
        ]}
        ariaLabel="Breadcrumbs"
      />
    },
    permission: "manage_prompts"
  },
  {
    path: "/insights",
    name: "Dashboard",
    Component: Insights,
    Breadcrumb: () => {
      const { t } = useTranslation();
      return <BreadcrumbGroup
        items={[
          { text: t("home.title"), href: "../" },
          { text: t("dashboard.title"), href: "insights" }
        ]}
        ariaLabel="Breadcrumbs"
      />
    },
    permission: "view_insights"
  },
  {
    path: "/prompt/:key",
    name: "Prompts",
    hide: true,
    Component: PromptEditor,
    Breadcrumb: () => {
      const { t } = useTranslation();
      const { key } = useParams();
      return <BreadcrumbGroup
        items={[
          { text: t("home.title"), href: "../" },
          { text: t("utilities.prompts"), href: "/prompts" },
          { text: key }
        ]}
        ariaLabel="Breadcrumbs"
      />
    },
    permission: "manage_prompts"
  },
  {
    path: "/dashboard/parsedFiles/search",
    name: "Search",
    Component: Search,
    Breadcrumb: () => {
      const { t } = useTranslation();
      return <BreadcrumbGroup
        items={[
          { text: t("home.title"), href: "../" },
          { text: t("utilities.search"), href: "search" }
        ]}
        ariaLabel="Breadcrumbs"
      />
    }
  },
  {
    path: "/dashboard/:key*",
    name: "Call Details",
    hide: true,
    Component: Dashboard,
    Breadcrumb: () => {
      const { t } = useTranslation();
      return <BreadcrumbGroup
        items={[
          { text: t('home.title'), href: "../../" },
          { text: t('callList'), href: "../../" },
          { text: t('callDetail'), href: "#" },
        ]}
        ariaLabel="Breadcrumbs"
      />
    }
  },
  {
    path: "/",
    name: "Call List",
    Component: Home,
    Breadcrumb: () => {
      const { t } = useTranslation();
      return <BreadcrumbGroup
        items={[
          { text: t("home.title"), href: "#" },
          { text: t("callList"), href: "#" },
        ]}
        ariaLabel="Breadcrumbs"
      />
    }
  },
];

function Navigation({ userName, email }) {
  const { t } = useTranslation();
  const [version, setVersion] = useState('');
  const { userRole, hasPermission } = useUserRole();

  useEffect(() => {
    fetch('/version.json')
      .then(response => response.json())
      .then(data => setVersion(data.version))
      .catch(error => console.error('Error fetching version:', error));
  }, []);

  const getUtilities = () => {
    const utilities = [];

    if (hasPermission("view_insights")) {
      utilities.push({
        type: "button",
        text: t('dashboard.title'),
        iconName: "multiscreen",
        href: "insights",
        externalIconAriaLabel: " (opens in a new tab)"
      });
    }

    if (hasPermission("manage_prompts")) {
      utilities.push({
        type: "button",
        text: t('utilities.prompts'),
        iconName: "script",
        href: "/prompts",
        externalIconAriaLabel: " (opens in a new tab)"
      });
    }

    if (hasPermission("manage_users")) {
      utilities.push({
        type: "button",
        text: t('utilities.users'),
        iconName: "group",
        href: "/users",
        externalIconAriaLabel: " (opens in a new tab)"
      });
    }

    if (hasPermission("view_dashboard")) {
      utilities.push({
        type: "button",
        text: t('utilities.search'),
        iconName: "search",
        href: "search",
        externalIconAriaLabel: " (opens in a new tab)"
      });
    }

    utilities.push({
      type: "menu-dropdown",
      text: userName,
      description: email,
      iconName: "user-profile",
      onItemClick: (event) => {
        console.log(event);
        if (event.detail.id === "signout") logOut();
      },
      items: [
        { id: "role", text: `Rol: ${userRole || 'Invitado'}` },
        { id: "version", text: `Version: ${version}` },
        { id: "signout", text: t('utilities.signout') }
      ]
    });

    return utilities;
  };

  return (    
      <TopNavigation
        identity={{
          href: "/",
          title:  <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt={t('headerImageAlt')} style={{ height: 25, marginRight: '8px' }} />
            {t('headerTitle')}
          </div>,
          iconName: "settings",
        }}
        i18nStrings={{
          searchIconAriaLabel: t('searchIconAriaLabel'),
          searchDismissIconAriaLabel: t('searchDismissIconAriaLabel'),
          overflowMenuTriggerText: t('overflowMenuTriggerText'),
          overflowMenuTitleText: t('overflowMenuTitleText'),
          overflowMenuBackIconAriaLabel: t('overflowMenuBackIconAriaLabel'),
          overflowMenuDismissIconAriaLabel: t('overflowMenuDismissIconAriaLabel')
        }}
        utilities={getUtilities()}
      />
  );
}


function ComponentWithAlert({ Component, ...props }) {
  const { setAlert } = useAlert();
  
  return <Component setAlert={setAlert} {...props} />;
}

function BottomAlertDisplay() {
  const { alert, dismissAlert } = useAlert();
  
  if (!alert) return null;
  
  return (
    <div style={{ 
      position: 'fixed', 
      bottom: '60px', 
      left: '0',
      width: '100%',
      zIndex: 9999,
    }}>
      <div style={{ 
        maxWidth: '600px', 
        margin: '0 auto', 
        padding: '0 20px'
      }}>
        <Alert
          variant={alert.variant}
          dismissible
          header={alert.heading}
          onDismiss={dismissAlert}
        >
          {alert.text}
        </Alert>
      </div>
    </div>
  );
}

function App() {
  const [alert, setAlert] = useState();

  const onDismiss = () => {
    setAlert(null);
  };

  const userToken = localStorage.getItem("id_token");
  const parsedToken = payloadFromToken(userToken);
  const cognitoUserName = parsedToken["cognito:username"] || "Unknown";
  const cognitoEmail = parsedToken["email"] || "Unknown";

  const renderRouteContent = (Component, Breadcrumb) => (props) => (
    <>
      <Navigation userName={cognitoUserName} email={cognitoEmail} />
      <AppLayout
        stickyNotifications
        toolsHide
        navigationHide
        breadcrumbs={<Breadcrumb/>}
        notifications={alert && (
          <Alert
            variant={alert.variant}
            dismissible
            header={alert.heading}
            onDismiss={onDismiss}
          >
            {alert.text}
          </Alert>
        )}
        content={<Component setAlert={setAlert} {...props} />}
      />
      <Footer />
    </>
  );

  return (
    <Router>
      <AlertProvider>
        <UploadProgressProvider>
          <Switch>
            <Route path="/access-denied">
              <Navigation userName={cognitoUserName} email={cognitoEmail} />
              <AppLayout
                stickyNotifications
                toolsHide
                navigationHide
                content={<AccessDenied />}
              />
              <Footer />
            </Route>

            {routes.map(({ path, Component, Breadcrumb, name, permission }) => 
              permission ? (
                <ProtectedRoute
                  key={path}
                  path={path}
                  permission={permission}
                  component={renderRouteContent(Component, Breadcrumb)}
                />
              ) : (
                <Route 
                  key={path} 
                  path={path}
                  render={renderRouteContent(Component, Breadcrumb)}
                />
              )
            )}
          </Switch>
        </UploadProgressProvider>
       </AlertProvider>
    </Router>
  );  
}

export default App;
