import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import AudioUploadProgressModal from '../components/AudioUploadProgressModal';
import AudioProcessingService from '../util/audioProcessingService';

const UploadProgressContext = createContext();

export const UploadProgressProvider = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showFloatingProgress, setShowFloatingProgress] = useState(false);
  const [uploadItems, setUploadItems] = useState([]);
  const [progressData, setProgressData] = useState({});
  const pollingIntervalRef = useRef(null);
  const lastCheckTimeRef = useRef(Date.now());
  const initialCheckRef = useRef(false);
  
  useEffect(() => {
    const checkActiveProcessing = async () => {
      if (initialCheckRef.current) return;
      
      try {
        console.log("Verificando activamente archivos en procesamiento...");
        const { hasActiveFiles, files } = await AudioProcessingService.checkForProcessingFiles();
        
        if (hasActiveFiles && files.length > 0) {
          console.log("Se encontraron archivos activos en el servidor:", files);
          
          const extractFileName = (item) => {
            if (item.fileName) return item.fileName;
            
            const fileId = item.fileId || '';
            const extensionMatch = fileId.match(/\.(mp3|wav|ogg|flac|m4a)$/i);
            if (extensionMatch) {
              const parts = fileId.split(/[\/\\]/);
              return parts[parts.length - 1];
            }
            
            if (fileId.includes('_')) {
              const parts = fileId.split('_');
              for (let i = parts.length - 1; i >= 0; i--) {
                if (parts[i].includes('.')) return parts[i];
              }
              return parts[parts.length - 1];
            }
            
            return fileId;
          };
          
          const newItems = files.map(item => ({
            label: extractFileName(item),
            fileId: item.fileId,
            file: { name: extractFileName(item) }
          }));
          
          const newProgress = {};
          newItems.forEach(item => {
            const file = files.find(f => f.fileId === item.fileId);
            newProgress[item.label] = {
              status: AudioProcessingService.mapStatusToUI(file?.status || 'processing'),
              progress: file?.progress || calculateProgress('processing'),
              error: file?.error || ''
            };
          });
          
          if (newItems.length > 0 && 
              (uploadItems.length === 0 || 
               !uploadItems.some(item => newItems.some(ni => ni.fileId === item.fileId)))) {
            
            console.log("Actualizando estado con nuevos archivos encontrados");
            setUploadItems(newItems);
            setProgressData(newProgress);
            setShowFloatingProgress(true);
            
            startPolling(newItems.map(i => i.fileId));
            
            saveState(newItems, newProgress);
          }
        }
      } catch (error) {
        console.error("Error verificando archivos en procesamiento:", error);
      } finally {
        initialCheckRef.current = true;
      }
    };
    
    if (!initialCheckRef.current) {
      checkActiveProcessing();
    }
    
    return () => {

      if (pollingIntervalRef.current) clearInterval(pollingIntervalRef.current);
    };
  }, []);
  
  const calculateOverallProgress = useCallback(() => {
    if (!uploadItems || uploadItems.length === 0) return 0;
    
    let totalProgress = 0;
    let itemsWithProgress = 0;
    
    uploadItems.forEach(item => {
      const progress = progressData[item.label]?.progress || 0;
      if (progress > 0) {
        totalProgress += progress;
        itemsWithProgress++;
      }
    });
    
    return itemsWithProgress > 0 ? Math.round(totalProgress / itemsWithProgress) : 0;
  }, [uploadItems, progressData]);

  const checkForActiveUploads = async (useTimeLimit = true) => {
    if (useTimeLimit) {
      const now = Date.now();
      if (now - lastCheckTimeRef.current < 1000) {
        return;
      }
      lastCheckTimeRef.current = now;
    }
    
    try {
      console.log("Verificando archivos activos...");
      
      // First check localStorage
      let hasActiveFilesInLocal = false;
      const savedState = localStorage.getItem('audioUploadState');
      
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        
        // Filter only active files
        const activeFiles = Object.values(parsedState).filter(file => 
          !['completed', 'error', 'cancelled'].includes(
            AudioProcessingService.mapStatusToUI(file.status)
          )
        );
        
        if (activeFiles.length > 0) {
          console.log(`Encontrados ${activeFiles.length} archivos activos guardados`);
          
          // Recreate lists for component
          const items = activeFiles.map(file => ({
            label: file.fileName,
            fileId: file.fileId,
            file: { name: file.fileName }
          }));
          
          const progress = {};
          activeFiles.forEach(file => {
            progress[file.fileName] = {
              status: AudioProcessingService.mapStatusToUI(file.status),
              progress: file.progress || calculateProgress(file.status),
              error: file.error || ''
            };
          });
          
          // Show progress
          if (items.length > 0) {
            setUploadItems(items);
            setProgressData(progress);
            setShowFloatingProgress(true);
            
            // Start polling
            startPolling(items.map(i => i.fileId));
          }
          
          hasActiveFilesInLocal = true;
        }
      }
      
      // If no active files in localStorage, check the service
      if (!hasActiveFilesInLocal) {
        console.log("No hay archivos activos en localStorage, consultando servicio...");
        
        try {
          const statusData = await AudioProcessingService.getSystemStatus();
          
          let processingItems = [];
          if (statusData && statusData.processing) {
            processingItems = Array.isArray(statusData.processing) ? 
              statusData.processing : [statusData.processing];
            
            processingItems = processingItems.filter(item => item && item.fileId);
          }
          
          if (processingItems.length > 0) {
            console.log("Encontrados elementos en procesamiento en el servicio:", processingItems);
            
            const extractFileName = (item) => {
              if (item.fileName && typeof item.fileName === 'string' && item.fileName.length > 0) {
                return item.fileName;
              }
              
              const fileId = item.fileId || '';
              
              const extensionMatch = fileId.match(/\.(mp3|wav|ogg|flac|m4a)$/i);
              if (extensionMatch) {
                const parts = fileId.split(/[\/\\]/);
                return parts[parts.length - 1];
              }
              
              if (fileId.includes('_')) {
                const parts = fileId.split('_');
                for (let i = parts.length - 1; i >= 0; i--) {
                  if (parts[i].includes('.')) {
                    return parts[i];
                  }
                }
                return parts[parts.length - 1];
              }
              
              return fileId;
            };
            
            const newUploadItems = processingItems.map(item => {
              const fileName = extractFileName(item);
              return {
                label: fileName,
                fileId: item.fileId,
                file: { name: fileName }
              };
            });
            
            // Create progress data
            const newProgress = {};
            newUploadItems.forEach(item => {
              const serviceItem = processingItems.find(i => i.fileId === item.fileId);
              newProgress[item.label] = {
                status: AudioProcessingService.mapStatusToUI(serviceItem?.status || 'processing'),
                progress: calculateProgress(serviceItem?.status || 'processing'),
                error: serviceItem?.error || ''
              };
            });
            
            // Only update if we have new items
            if (newUploadItems.length > 0) {
              // Don't update if we're already tracking these items
              const alreadyTracking = uploadItems.some(item => 
                newUploadItems.some(newItem => newItem.fileId === item.fileId)
              );
              
              if (!alreadyTracking) {
                setUploadItems(newUploadItems);
                setProgressData(newProgress);
                setShowFloatingProgress(true);
                
                // Start polling
                startPolling(newUploadItems.map(i => i.fileId));
                
                // Save to localStorage
                saveState(newUploadItems, newProgress);
              }
            }
          }
        } catch (err) {
          console.error("Error al verificar estado del sistema:", err);
        }
      }
    } catch (error) {
      console.warn("Error verificando archivos guardados:", error);
    }
  };
  
  const checkCompletedFiles = useCallback((records) => {
    if (!records || uploadItems.length === 0) return false;
    
    let updated = false;
    const updatedProgressData = {...progressData};
    
    records.forEach(record => {
      const fileName = record.jobName || record.guid;
      const matchingItem = uploadItems.find(item => item.label === fileName);
      
      if (matchingItem && ['Done', 'Ejecutada correctamente'].includes(record.status)) {
        updatedProgressData[fileName] = {
          ...progressData[fileName],
          status: 'completed',
          progress: 100
        };
        updated = true;
      }
    });
    
    if (updated) {
      setProgressData(updatedProgressData);
      saveState(uploadItems, updatedProgressData);
      return true;
    }
    
    return false;
  }, [uploadItems, progressData]);

  const startPolling = (fileIds) => {
    if (!fileIds || fileIds.length === 0) return;
    
    // Clear previous interval if it exists
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
    }
    
    pollingIntervalRef.current = setInterval(async () => {
      try {
        const response = await AudioProcessingService.getBatchStatus(fileIds);
        if (!response) {
          console.warn("No se recibió respuesta del servicio");
          return;
        }
        
        console.log("Respuesta de polling:", response);
        
        const status = response;
        
        // Update state with response
        let allComplete = true;
        let updatedProgressData = {...progressData};
        let hasChanges = false;
        
        Object.entries(status).forEach(([fileId, data]) => {
          const item = uploadItems.find(i => i.fileId === fileId);
          if (item) {
            // Make sure the label is displayed correctly
            const fileName = item.label || fileId.split(/[\/_]/).pop() || fileId;
            
            // Only update if status or progress has changed
            const currentData = progressData[fileName] || {};
            if (
              currentData.status !== data.status || 
              currentData.progress !== (data.progress || calculateProgress(data.status)) ||
              currentData.error !== (data.message || '')
            ) {
              updatedProgressData[fileName] = {
                status: data.status,
                progress: data.progress || calculateProgress(data.status),
                error: data.message || ''
              };
              hasChanges = true;
            }
            
            if (!['completed', 'error', 'cancelled'].includes(data.status)) {
              allComplete = false;
            }
          }
        });
        
        // Only update state if something changed
        if (hasChanges) {
          setProgressData(updatedProgressData);
          
          // Update localStorage
          saveState(uploadItems, updatedProgressData);
        }
        
        // Stop polling if all items are complete
        if (allComplete) {
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = null;
          
          if (uploadItems.length > 0) {
            setTimeout(() => {
              handleAllUploadsComplete();
            }, 3000); // Hide after 3 seconds when all are complete
          }
        }
      } catch (error) {
        console.error("Error en polling:", error);
      }
    }, 5000);
  };

  const updateFileProgress = useCallback((filename, progress, status = 'uploading', error = '') => {
    setProgressData(prev => ({
      ...prev,
      [filename]: {
        progress,
        status,
        error
      }
    }));
  }, []);
  
  // Show processing progress
  const showProcessingProgress = useCallback((items, progress) => {
    if (!items || items.length === 0) return;
    
    // Check if items or progress have changed before updating state
    const itemsChanged = JSON.stringify(items) !== JSON.stringify(uploadItems);
    const progressChanged = JSON.stringify(progress) !== JSON.stringify(progressData);
    
    if (itemsChanged || progressChanged) {
      setUploadItems(items);
      setProgressData(progress);
      setIsModalVisible(true);
      
      saveState(items, progress);
      
      // Start/restart polling with new item IDs
      if (items.length > 0) {
        startPolling(items.map(i => i.fileId));
      }
    }
  }, [uploadItems, progressData]);
  
  // Save state
  const saveState = (items, progress) => {
    try {
      const state = {};
      items.forEach(item => {
        const data = progress[item.label];
        if (data) {  // Make sure there's progress data for this item
          state[item.fileId] = {
            fileId: item.fileId,
            fileName: item.label,
            status: data.status,
            progress: data.progress,
            error: data.error
          };
        }
      });
      
      localStorage.setItem('audioUploadState', JSON.stringify(state));
    } catch (error) {
      console.warn("Error guardando estado:", error);
    }
  };
  
  const handleMaximize = () => {
    setShowFloatingProgress(false);
    setIsModalVisible(true);
  };
  
  const handleModalDismiss = () => {
    setIsModalVisible(false);
    
    const hasActiveUploads = Object.values(progressData).some(
      item => !['completed', 'error', 'cancelled'].includes(item.status)
    );
    
    if (hasActiveUploads) {
      setShowFloatingProgress(true);
    }
  };
  
  const handleAllUploadsComplete = () => {
    setShowFloatingProgress(false);
  };
  
  // Use the service method instead of duplicating the function
  const mapStatusToUI = (status) => {
    return AudioProcessingService.mapStatusToUI(status);
  };
  
  // Periodically check for processing files in the system but less frequently
  // Only run this check if we're not already polling
  useEffect(() => {
    const systemCheckInterval = setInterval(async () => {
      try {
        // Only check if we're not in an active polling state and we have no active items
        if (!pollingIntervalRef.current && uploadItems.length === 0) {
          const { hasActiveFiles } = await AudioProcessingService.checkForProcessingFiles();
          
          if (hasActiveFiles) {
            console.log("Sistema tiene elementos en procesamiento, verificando estado local");
            await checkForActiveUploads(true);
          }
        }
      } catch (err) {
        console.error("Error al verificar estado del sistema:", err);
      }
    }, 30000); // Check every 30 seconds instead of 15 to reduce unnecessary calls
    
    return () => clearInterval(systemCheckInterval);
  }, [uploadItems]);
  
  const calculateProgress = (status) => {
    const statusProgress = {
      'transcribing': 60,
      'Transcribing': 60,
    };
    
    return statusProgress[status] || 50;
  };

  return (
    <UploadProgressContext.Provider 
      value={{ 
        checkCompletedFiles,
        calculateOverallProgress,
        showProcessingProgress,
        updateFileProgress,
        uploadItems,
        progressData,
        setIsModalVisible,
        handleMaximize,
        handleModalDismiss
      }}
    >
      {children}
      
      <AudioUploadProgressModal
        visible={isModalVisible}
        onDismiss={handleModalDismiss}
        uploadItems={uploadItems}
        progressData={progressData}
      />
      
      {showFloatingProgress && uploadItems.length > 0 && (
        <div 
          onClick={handleMaximize}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#f0f0f0',
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            cursor: 'pointer'
          }}
        >
          Procesando {uploadItems.length} archivo(s): {calculateOverallProgress()}%
        </div>
      )}
    </UploadProgressContext.Provider>
  );
};

export const useUploadProgress = () => {
  const context = useContext(UploadProgressContext);
  if (!context) {
    throw new Error('useUploadProgress debe usarse dentro de UploadProgressProvider');
  }
  return context;
};