import React, { useRef, useState, useEffect } from "react";
import { Button, Grid, Input } from '@cloudscape-design/components';
import { useTranslation } from 'react-i18next';
import { useUserRole } from "../api/role";

export const ChatInput = ({submitQuery}) => {
  const [inputQuery, setInputQuery] = useState("");
  const { t } = useTranslation();
  const { hasPermission } = useUserRole();
  const [isNarrow, setIsNarrow] = useState(false);
  const containerRef = useRef(null);
  
  useEffect(() => {
    if (!containerRef.current) return;
    
    const resizeObserver = new ResizeObserver(entries => {
      const { width } = entries[0].contentRect;
      setIsNarrow(width < 600);
    });
    
    resizeObserver.observe(containerRef.current);
    
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const onSubmit = (e) => {
    submitQuery(inputQuery);
    setInputQuery('');
    e.preventDefault();
    return true;
  }

  const sendIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white">
      <path d="M2 14L14 8L2 2V6.5L10 8L2 9.5V14Z" fill="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  return (
    <form ref={containerRef} onSubmit={onSubmit} style={{ width: '100%', margin: '0 auto' }}>
      <Grid 
        gridDefinition={[
          { colspan: { default: 11, xxs: 11 } }, 
          { colspan: { default: 1, xxs: 1 } }
        ]}
        disableGutters={true}
      >
        <Input
          placeholder={t("chatInputPlaceholder")}
          onChange={({ detail }) => setInputQuery(detail.value)}
          value={inputQuery}
          disabled={!hasPermission("manage_prompts")}
        />
        <Button 
          type="submit"
          variant="primary"
          fullWidth
          iconSvg={isNarrow ? sendIcon : undefined}
          id="chat-submit-button"
          disabled={!hasPermission("manage_prompts")}>
          {isNarrow ? "" : t("submit")}
        </Button>
      </Grid>
    </form>
  );
};