import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUserRole } from '../api/role';
import {
  Container,
  Box,
  Spinner
} from "@cloudscape-design/components";

export const ProtectedRoute = ({ component: Component, permission, ...rest }) => {
    const { hasPermission, isLoading } = useUserRole();
  
    return (
        <Route
        {...rest}
        render={(props) => {
            if (isLoading) {
                return (
                    <Container>
                        <Box textAlign="center" padding={{ bottom: "l" }}>
                            <Spinner size="large" />
                        </Box>
                    </Container>
                )
            }
            if (!permission) {
                return <Component {...props} />;
            }
            return hasPermission(permission) ? (
                <Component {...props} />
                ) : (
                <Redirect
                    to={{
                    pathname: "/access-denied",
                    state: { from: props.location }
                    }}
                />
            );
        }}
        />
    );
};