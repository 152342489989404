import React, { useEffect, useState } from 'react';
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import Input from "@cloudscape-design/components/input";
import Select from "@cloudscape-design/components/select";
import { ContentLayout, Modal } from "@cloudscape-design/components";
import { useTranslation } from 'react-i18next';
import { users, createUser, deleteUser } from "../api/api";
import { validateEmail, validateUsername } from "../util/validators";
import AlertNotification from '../components/AlertNotification';

function Users() {
    const { t } = useTranslation();
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableItems, setTableItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [newUser, setNewUser] = useState({ name: "", email: "", role: "Admin" });
    const [newUserErrors, setNewUserErrors] = useState({ name: "", email: "" });
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const [alert, setAlert] = useState(null);
    const clearAlert = () => setAlert(null);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [filteredItems, setFilteredItems] = useState([]);
    const [filteringText, setFilteringText] = useState("");
    const PAGE_SIZE = 5;

    const roleOptions = [
        { value: 'Admin', label: 'Admin' },
        { value: 'Analista', label: 'Analista' },
        { value: 'Invitado', label: 'Invitado' }
    ];

    const fetchUsers = async () => {
        try {
            const response = await users();
            setTableItems(response);
        } catch (err) {
            setAlert({ type: "error", message: err.message });
        }
    };
    
    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        if (filteringText) {
            const lowerFilteringText = filteringText.toLowerCase();
            const filtered = tableItems.filter(
                item => 
                    item.name.toLowerCase().includes(lowerFilteringText) ||
                    item.email.toLowerCase().includes(lowerFilteringText) ||
                    item.role.toLowerCase().includes(lowerFilteringText)
            );
            setFilteredItems(filtered);
        } else {
            setFilteredItems(tableItems);
        }
        setCurrentPageIndex(1);
    }, [filteringText, tableItems]);

    const pagesCount = Math.ceil(filteredItems.length / PAGE_SIZE);
    const startIndex = (currentPageIndex - 1) * PAGE_SIZE;
    const paginatedItems = filteredItems.slice(startIndex, startIndex + PAGE_SIZE);

    const handleDelete = async () => {
        try {
            setIsDeletingUser(true);
            await deleteUser(userToDelete.name);
            setShowModal(false);
            await fetchUsers();
            setAlert({ type: "success", message: t("users.deleteSuccess") });
            setUserToDelete(null);
        } catch (err) {
            setAlert({ type: "error", message: err.message });
        } finally {
            setIsDeletingUser(false);
        }
    };

    const validateField = (key, value) => {
        const duplicate = tableItems.find(item => item[key].toLowerCase() === value.trim().toLowerCase());
        return duplicate ? `${t("users." + key)} ya existe` : "";
    };

    const handleInputChange = (key, value) => {
        setNewUser(prev => ({ ...prev, [key]: value }));
        if (key === "email") {
            const duplicateError = validateField(key, value);
            const formatError = validateEmail(value);
            setNewUserErrors((prev) => ({
                ...prev,
                [key]: duplicateError || formatError,
            }));
        }
        if (key === "name") {
            const duplicateError = validateField(key, value);
            const formatError = validateUsername(value, t);
            setNewUserErrors((prev) => ({ 
                ...prev, 
                [key]: duplicateError || formatError 
            }));
        }
    };

    const handleAddUser = async () => {
        if(newUserErrors.name || newUserErrors.email || !newUser.name.trim() || !newUser.email.trim()){
            return;
        }

        const cleanUser = {
            name: newUser.name.trim(),
            email: newUser.email.trim(),
            role: newUser.role
        };

        setIsAddingUser(true);
        try {
            await createUser(cleanUser);
            await fetchUsers();
            setShowAddUserModal(false);
            setAlert({ type: "success", message: t("users.createSuccess") });
        } catch (err) {
            setAlert({ type: "error", message: t("users.createError") });
            setShowAddUserModal(false);
        } finally {
            setIsAddingUser(false);
        }
    };

    return (
        <ContentLayout 
            header={
                <Header variant="h1">
                    {t("utilities.users")}
                </Header>
            }
        >
            <AlertNotification alert={alert} clearAlert={clearAlert} t={t} />
            <Table
                renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
                    `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                }
                onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                selectedItems={selectedItems}
                ariaLabels={{
                    selectionGroupLabel: "Items selection",
                    allItemsSelectionLabel: () => "select all",
                    itemSelectionLabel: ({ selectedItems }, item) => item.name
                }}
                columnDefinitions={[
                    {
                        id: "name",
                        header: t("users.name"),
                        cell: item => item.name,
                        sortingField: "name",
                        isRowHeader: true
                    },
                    {
                        id: "email",
                        header: t("users.email"),
                        cell: item => item.email,
                        sortingField: "email"
                    },
                    {
                        id: "role",
                        header: t("users.role"),
                        cell: item => item.role
                    },
                    {
                        id: "status",
                        header: t("users.status"),
                        cell: item => {
                            switch (item.userStatus) {
                                case "FORCE_CHANGE_PASSWORD":
                                    return t("users.changePassword");
                                case "CONFIRMED":
                                    return t("users.confirmed");
                                default:
                                    return item.userStatus;
                            }
                        }
                    },
                    {
                        id: "actions",
                        header: t("users.actions"),
                        cell: item => (
                            <Button 
                                iconName="remove" 
                                variant="icon" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setUserToDelete(item);
                                    setShowModal(true);
                                }} 
                            />
                        ),
                        minWidth: 170
                    }
                ]}
                columnDisplay={[
                    { id: "name", visible: true },
                    { id: "email", visible: true },
                    { id: "role", visible: true },
                    { id: "status", visible: true },
                    { id: "actions", visible: true }
                ]}
                enableKeyboardNavigation
                items={paginatedItems}
                loadingText="Cargando"
                stickyColumns={{ first: 0, last: 1 }}
                trackBy="name"
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>{t("users.noResources")}</b>
                            <Button onClick={() => setShowAddUserModal(true)}>
                                {t("users.addUser")}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                filter={
                    <TextFilter
                        filteringPlaceholder={t("users.searchPlaceholder")} 
                        filteringText={filteringText}
                        onChange={({ detail }) => setFilteringText(detail.filteringText)}
                        countText={filteredItems.length > 0 
                            ? `${filteredItems.length} ${filteredItems.length === 1 
                                ? t("users.result") 
                                : t("users.results")}`
                            : t("users.noResults")
                        }
                    />
                }
                header={
                    <Header
                        counter={`(${tableItems.length})`}
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button 
                                    onClick={() => {
                                        setNewUser({ name: "", email: "", role: "Invitado" });
                                        setNewUserErrors({ name: "", email: "" });
                                        setShowAddUserModal(true);
                                    }} 
                                    variant="primary"
                                >
                                    {t("users.addUser")}
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        {t("users.usersList")}
                    </Header>
                }
                pagination={
                    <Pagination 
                        currentPageIndex={currentPageIndex} 
                        onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
                        pagesCount={pagesCount} 
                        ariaLabels={{
                            nextPageLabel: t("pagination.next"),
                            previousPageLabel: t("pagination.previous"),
                            pageLabel: pageNumber => `${pageNumber} de ${pagesCount}`
                        }}
                    />
                }
            />
            {showModal && (
                <Modal
                    visible={showModal}
                    onDismiss={() => setShowModal(false)}
                    header={t("confirmModal.title")}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setShowModal(false)} variant="link">
                                    {t("confirmModal.cancel")}
                                </Button>
                                <Button 
                                    onClick={handleDelete} 
                                    variant="primary"
                                    loading={isDeletingUser}
                                >
                                    {t("confirmModal.confirm")}
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <p>{t("confirmModal.message")}</p>
                </Modal>
            )}
            {showAddUserModal && (
                <Modal
                    visible={showAddUserModal}
                    onDismiss={() => setShowAddUserModal(false)}
                    header="Agregar usuario"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setShowAddUserModal(false)} variant="link">
                                    {t("confirmModal.cancel")}
                                </Button>
                                <Button 
                                    onClick={handleAddUser} 
                                    variant="primary"
                                    loading={isAddingUser}
                                >
                                    {t("confirmModal.add")}
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <SpaceBetween size="l">
                        <Input
                            value={newUser.name}
                            onChange={e => handleInputChange("name", e.detail.value)}
                            placeholder="Nombre de usuario"
                            label="Nombre"
                            invalid={!!newUserErrors.name}
                            errorText={newUserErrors.name}
                        />
                        <Input
                            value={newUser.email}
                            onChange={e => handleInputChange("email", e.detail.value)}
                            placeholder="Correo electrónico"
                            label="Email"
                            invalid={!!newUserErrors.email}
                            errorText={newUserErrors.email}
                        />
                        <Select
                            selectedOption={roleOptions.find(option => option.value === newUser.role)}
                            onChange={e => handleInputChange("role", e.detail.selectedOption.value)}
                            options={roleOptions}
                            label="Rol"
                        />
                    </SpaceBetween>
                </Modal>
            )}
        </ContentLayout>
    );
}

export default Users;