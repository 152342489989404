import { getStats } from "../api/api";

/**
 * Service for checking and managing audio processing status
 */
const AudioProcessingService = {
  /**
   * Check for files currently being processed in the system
   * @returns {Promise<{hasActiveFiles: boolean, files: Array}>} Object with active status and file list
   */
  async checkForProcessingFiles() {
    try {
      // Get the full system status
      const response = await this.getSystemStatus();
      
      if (!response) {
        return { hasActiveFiles: false, files: [] };
      }
      
      // Check if there are any processing files
      const processingFiles = [];
      
      // Look for processing files
      if (response.processing) {
        const processingData = Array.isArray(response.processing) 
          ? response.processing 
          : [response.processing];
          
        // Filter out null or undefined entries
        processingFiles.push(...processingData.filter(item => item && item.fileId));
      }
      
      // Also check queued files
      if (response.queued) {
        const queuedData = Array.isArray(response.queued) 
          ? response.queued 
          : [response.queued];
          
        processingFiles.push(...queuedData.filter(item => item && item.fileId));
      }
      
      return {
        hasActiveFiles: processingFiles.length > 0,
        files: processingFiles
      };
    } catch (error) {
      console.error('Error checking for processing files:', error);
      return { hasActiveFiles: false, files: [] };
    }
  },

  /**
   * Get status for a single file
   * @param {string} fileId - File ID to check
   * @returns {Promise<Object>} Status object
   */
  async getFileStatus(fileId) {
    try {
      // Call the stats endpoint with a specific fileId
      const response = await getStats({fileId});
      
      // If we don't have a response or data, return error
      if (!response || !response.data) {
        return {
          status: 'error',
          progress: 0,
          message: 'Invalid response'
        };
      }

      // Look for the file in all categories
      const categories = ['processing', 'completed', 'errors', 'cancelled', 'queued'];
      for (const category of categories) {
        // Handle both array and object cases
        if (response.data[category]) {
          if (Array.isArray(response.data[category])) {
            const found = response.data[category].find(item => item.fileId === fileId);
            if (found) {
              return {
                status: mapStatusToUI(found.status || category),
                progress: found.progress || this.calculateProgress(found.status || category),
                message: found.error || ''
              };
            }
          } else if (response.data[category].fileId === fileId) {
            // Direct object match
            const found = response.data[category];
            return {
              status: mapStatusToUI(found.status || category),
              progress: found.progress || this.calculateProgress(found.status || category),
              message: found.error || ''
            };
          }
        }
      }
      
      // File not found
      return {
        status: 'error',
        progress: 0,
        message: 'File not found'
      };
    } catch (error) {
      console.error(`Error fetching status for file ${fileId}:`, error);
      return {
        status: 'error',
        progress: 0,
        message: error.message || 'Failed to fetch status'
      };
    }
  },

  /**
   * Get status for multiple files
   * @param {Array<string>} fileIds - Array of file IDs to check
   * @returns {Promise<Object>} Status objects keyed by fileId
   */
  async getBatchStatus(fileIds) {
    if (!fileIds || fileIds.length === 0) {
      return {};
    }
    
    try {
      const response = await getStats();
      
      // If we get an invalid response, log it and return empty object
      if (!response) {
        console.error('Invalid API response: null or undefined');
        return {};
      }
      
      // Format the response to match what the UI expects
      const formattedStatus = {};
      
      // Handle response with or without .data property
      const responseData = response.data || response;
      
      // Process all categories
      const categories = ['processing', 'completed', 'errors', 'cancelled', 'queued'];
      for (const category of categories) {
        if (responseData[category]) {
          // Handle both array and single object cases
          const items = Array.isArray(responseData[category]) 
            ? responseData[category] 
            : [responseData[category]];
          
          items.forEach(item => {
            if (item && item.fileId && fileIds.includes(item.fileId)) {
              formattedStatus[item.fileId] = {
                status: mapStatusToUI(item.status || category), // Use category if status is not provided
                progress: item.progress || this.calculateProgress(item.status || category),
                message: item.error || ''
              };
            }
          });
        }
      }
      
      // Add any missing fileIds as "not found"
      fileIds.forEach(fileId => {
        if (!formattedStatus[fileId]) {
          formattedStatus[fileId] = {
            status: 'error',
            progress: 0,
            message: 'File not found'
          };
        }
      });
      
      return formattedStatus;
    } catch (error) {
      console.error('Error fetching batch status:', error);
      
      // Return error status for all files
      const errorStatuses = {};
      fileIds.forEach(fileId => {
        errorStatuses[fileId] = {
          status: 'error',
          progress: 0,
          message: error.message || 'Failed to fetch status'
        };
      });
      
      return errorStatuses;
    }
  },
  
  /**
   * Get system-wide status for all files
   * @returns {Promise<Object>} Complete status information
   */
  async getSystemStatus() {
    try {
      const response = await getStats();
      return response.data || response;
    } catch (error) {
      console.error('Error fetching system status:', error);
      return {
        processing: [],
        completed: [],
        errors: [],
        cancelled: [],
        queued: []
      };
    }
  },

  /**
   * Check if a status is terminal (completed, error, cancelled)
   * @param {string} status - Status to check
   * @returns {boolean} True if terminal
   */
  isTerminalState(status) {
    return ['completed', 'error', 'cancelled', 'done', 'failed', 'Ejecutada correctamente', 'Falló', 'Error detectado', 'Cancelada'].includes(status);
  },

  /**
   * Map backend status to UI status
   * @param {string} backendStatus - Status from backend
   * @returns {string} UI status
   */
  mapStatusToUI(backendStatus) {
    return mapStatusToUI(backendStatus);
  },

  /**
   * Calculate progress based on status
   * @param {string} status - Status
   * @param {number} uploadProgress - Upload progress
   * @param {number} providedProgress - Progress from backend
   * @returns {number} Progress percentage
   */
  calculateProgress(status, uploadProgress = 100, providedProgress = null) {
    // Use provided progress if available
    if (providedProgress !== null) {
      return providedProgress;
    }
    
    // Map Spanish statuses
    const statusProgress = {
      // English statuses
      'pending': uploadProgress,
      'queued': uploadProgress,
      'processing': 90,
      'transcribing': 90,
      'analyzing': 95,
      'done': 100,
      'completed': 100,
      'error': uploadProgress,
      'failed': uploadProgress,
      'cancelled': uploadProgress,
      
      // Spanish statuses
      'En progreso': 90,
      'Falló': uploadProgress,
      'Error detectado': uploadProgress,
      'Cancelada': uploadProgress,
      'Ejecutada correctamente': 100
    };
    
    return statusProgress[status] || uploadProgress;
  }
};

/**
 * Map backend status to UI status (internal helper)
 */
function mapStatusToUI(status) {
  if (!status) return 'processing';
  
  const statusMap = {
    // English statuses
    'pending': 'pending',
    'queued': 'pending',
    'processing': 'processing',
    'transcribing': 'processing',
    'Transcribing': 'processing', 
    'analyzing': 'processing',
    'done': 'completed',
    'completed': 'completed',
    'error': 'error',
    'failed': 'error',
    'cancelled': 'cancelled',
    
    // Spanish statuses
    'En progreso': 'processing',
    'Falló': 'error',
    'Error detectado': 'error',
    'Cancelada': 'cancelled',
    'Ejecutada correctamente': 'completed'
  };
  
  return statusMap[status] || 'processing';
}

export default AudioProcessingService;