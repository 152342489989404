/**
 * Validates the email address format
 * @param {string} email - The email address to validate
 * @returns {boolean} True if the email is invalid, false otherwise
 */
export const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email) ? false : true;
};

/**
 * Validates the username format
 * @param {string} name - The username to validate
 * @param {Function} t - The translation function from react-i18next
 * @returns {string} An error message if the username is invalid, an empty string otherwise
 */
export const validateUsername = (name, t) => {
    if (!name || name.trim() === '') {
        return t("users.nameRequired");
    }
    
    const pattern = /^[\p{L}\p{M}\p{S}\p{N}\p{P}]+$/u;
    if (!pattern.test(name.trim())) {
        return t("users.nameInvalidFormat");
    }
    
    return "";
};