
import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  Box, 
  SpaceBetween, 
  ProgressBar, 
  Button, 
  StatusIndicator,
  Container,
  Header,
} from '@cloudscape-design/components';
import { useTranslation } from 'react-i18next';

/**
 * Modal component that displays upload progress for audio files
 */
const AudioUploadProgressModal = ({ 
  visible, 
  onDismiss, 
  uploadItems = [], 
  progressData = {},
  onCancel
}) => {
  const { t } = useTranslation();
  
  const calculateOverallProgress = () => {
    if (uploadItems.length === 0) return 0;
    
    let totalProgress = 0;
    let itemsWithProgress = 0;
    
    uploadItems.forEach(item => {
      const fileProgress = progressData[item.label]?.progress || 0;
      if (fileProgress > 0) {
        totalProgress += fileProgress;
        itemsWithProgress++;
      }
    });
    
    return itemsWithProgress > 0 
      ? Math.round(totalProgress / itemsWithProgress) 
      : 0;
  };
  
  const getStatusType = (filename) => {
    const fileData = progressData[filename];
    if (!fileData) return 'pending';
    
    switch(fileData.status) {
      case 'uploading': return 'in-progress';
      case 'processing': return 'in-progress';
      case 'completed': return 'success';
      case 'error': return 'error';
      default: return 'in-progress'; 
    }
  };

  const getStatusText = (filename) => {
    const fileData = progressData[filename];
    if (!fileData) return t('upload.pending', 'Pending');
    
    switch(fileData.status) {
      case 'uploading': return t('upload.uploading', 'Uploading');
      case 'processing': return t('upload.processing', 'Processing');
      case 'completed': return t('upload.completed', 'Completed');
      case 'error': return t('upload.error', 'Error');
      default: return t('upload.pending', 'Pending');
    }
  };

  const completedCount = uploadItems.filter(item => 
    progressData[item.label]?.status === 'completed'
  ).length;

  return (
      <Modal
        visible={visible}
        onDismiss={onDismiss}
        header={t('upload.progressTitle', 'Audio Upload Progress')}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={onDismiss}>
                {t('upload.close', 'Close')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        size="medium"
      >
      <SpaceBetween size="l">
        {uploadItems.length > 0 && (
          <Container
            header={
              <Header variant="h3">{t('upload.overallProgress', 'Overall Progress')}</Header>
            }
          >
            <ProgressBar 
              value={calculateOverallProgress()} 
              label={`${calculateOverallProgress()}%`}
              description={`${completedCount} ${t('upload.of', 'of')} ${uploadItems.length} ${t('upload.filesCompleted', 'files completed')}`}
            />
          </Container>
        )}
        
        <Container
          header={
            <Header variant="h3">{t('upload.files', 'Files')}</Header>
          }
        >
          <SpaceBetween size="m">
            {uploadItems.map((item, index) => {
              const fileProgress = progressData[item.label]?.progress || 0;
              const fileStatus = progressData[item.label]?.status || 'pending';
              const fileError = progressData[item.label]?.error || '';
              
              return (
                <Box key={index} padding="s">
                  <SpaceBetween size="s">
                    <div>
                      <strong>{item.file?.name || item.label}</strong>
                      <div>
                        <StatusIndicator type={getStatusType(item.label)}>
                          {getStatusText(item.label)}
                        </StatusIndicator>
                      </div>
                    </div>
                    <ProgressBar 
                        value={fileProgress} 
                        label={`${fileProgress}%`}
                        status={fileStatus === 'error' && fileError ? 'error' : undefined}
                        additionalInfo={fileStatus === 'error' && fileError ? fileError : undefined}
                      />
                    {fileStatus !== 'completed' && fileStatus !== 'error' && onCancel && (
                      <Button 
                        onClick={() => onCancel(index)}
                        variant="link"
                      >
                        {t('upload.cancel', 'Cancel')}
                      </Button>
                    )}
                  </SpaceBetween>
                </Box>
              );
            })}
            
            {uploadItems.length === 0 && (
              <Box textAlign="center">
                {t('upload.noActiveUploads', 'No active uploads')}
              </Box>
            )}
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </Modal>
  );
};

export default AudioUploadProgressModal;