import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  ContentLayout,
  Container,
  Header,
  Cards,
  Box,
  Button,
  Spinner,
  Alert,
  Icon
} from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { getPromptTemplates } from "../api/api";

const TEMPLATE_ICONS = {
  "LLMPromptQualityEvalTemplate": "file-open",
  "LLMCallConflictTemplate": "bug",
  "LLMPromptSpeakerTemplate": "user-profile",
  "LLMPromptSummaryTemplate": "suggestions",
  "LLMPromptSummaryKeyValue": "key",
  "LLMPromptVOCSummaryTemplate": "audio-full",
  "LLMPromptQueryTemplate": "contact"
};

const TEMPLATE_DESCRIPTIONS = {
  "LLMPromptQualityEvalTemplate": "templates.qualityEval",
  "LLMCallConflictTemplate": "templates.callConflict",
  "LLMPromptSpeakerTemplate": "templates.speaker",
  "LLMPromptSummaryTemplate": "templates.summary",
  "LLMPromptSummaryKeyValue": "templates.summaryKeyValue",
  "LLMPromptVOCSummaryTemplate": "templates.vocSummary",
  "LLMPromptQueryTemplate": "templates.query"
};

function Prompts() {
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTemplateTypes = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const response = await getPromptTemplates();
        setTemplates(response);
      } catch (error) {
        setError(t("templates.error"));
      } finally {
        setLoading(false);
      }
    };
  
    fetchTemplateTypes();
  }, [t]);

  const handleCardClick = (item) => {
    history.push(`/prompt/${item}`);
  };

  const getTemplateDescription = (templateId) => {
    if (TEMPLATE_DESCRIPTIONS[templateId]) {
      return t(TEMPLATE_DESCRIPTIONS[templateId]);
    }
    return templateId;
  };

  const formatCamelCaseText = (text) => {
    return text.replace(/([A-Z])/g, (match, letter, index) => {
      return index === 0 ? letter : `\u200B${letter}`;
    });
  };

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description={t("prompts.description")}
        >
          {t("utilities.prompts")}
        </Header>
      }
    >
      {error && (
        <Alert type="error" dismissible onDismiss={() => setError(null)}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Container>
          <Box textAlign="center" padding={{ bottom: "l" }}>
            <Spinner size="large" />
          </Box>
        </Container>
      ) : (
        <Cards
          items={templates}
          cardDefinition={{
            header: item => (
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Icon name={TEMPLATE_ICONS[item] || "file"} style={{ flexShrink: 0 }} />
                <span style={{ 
                  overflow: "visible",
                  whiteSpace: "normal",
                  wordBreak: "break-word"
                }}>{formatCamelCaseText(item)}</span>
              </div>
            ),
            sections: [
              {
                id: "description",
                header: t("description"),
                content: item => getTemplateDescription(item)
              },
              {
                id: "actions",
                content: item => (
                    <Button 
                        iconName="edit" 
                        onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleCardClick(item);
                        }}
                    >
                        {t("edit")}
                    </Button>
                )
              }
            ]
          }}
          cardsPerRow={[
            { cards: 1, minWidth: 0 },
            { cards: 2, minWidth: 500 },
            { cards: 3, minWidth: 992 }
          ]}
          selectedItems={selectedItems}
          onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
          header={
            <Header
              counter={`(${templates.length})`}
              variant="h2"
            >
              {t("prompts.availableTemplates")}
            </Header>
          }
          trackBy="id"
          visibleSections={["description", "actions"]}
          empty={
            <Box textAlign="center" color="inherit">
              <b>{t("prompts.noTemplates")}</b>
              <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                {t("prompts.noTemplatesDescription")}
              </Box>
            </Box>
          }
        />
      )}
    </ContentLayout>
  );
}

export default Prompts;