import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  ContentLayout,
  Container,
  Header,
  SpaceBetween,
  FormField,
  Textarea,
  Button,
  Input,
  Alert,
  Box,
  Table,
  Modal,
  Spinner,
} from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { getPromptDetails, createPrompt, updatePrompt, deletePrompt } from "../api/api";

function PromptEditor() {
  const { key: templateId } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [prompts, setPrompts] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [newPrompt, setNewPrompt] = useState({ key: "", value: "" });
  const [notification, setNotification] = useState(null);
  const [saveInProgress, setSaveInProgress] = useState(false);
  
  const isKeyValueTemplate = templateId === "LLMPromptSummaryKeyValue";

  useEffect(() => {
    const fetchPromptDetails = async () => {
      try {
        setLoading(true);
        const response = await getPromptDetails(templateId);
        setPrompts(response || []);
      } catch (error) {
        setNotification({ 
          type: "error", 
          content: t("prompts.errorLoading", { message: error.message || t("prompts.error") }) 
        });
      } finally {
        setLoading(false);
      }
    };
  
    fetchPromptDetails();
  }, [templateId, t]);

  const handleAddPrompt = async () => {
    try {
      setSaveInProgress(true);
      if (!newPrompt.key || !newPrompt.value) {
        setNotification({ type: "error", content: t("prompts.requiredFields") });
        return;
      }

      const promptExists = prompts.some(prompt => 
        prompt.key.toLowerCase() === newPrompt.key.toLowerCase()
      );

      if (promptExists) {
        setNotification({ type: "error", content: t("prompts.duplicateId", { key: newPrompt.key }) });
        setSaveInProgress(false);
        return;
      }

      await createPrompt(templateId, newPrompt.key, newPrompt.value);
      
      setPrompts([...prompts, newPrompt]);
      setShowAddModal(false);
      setNewPrompt({ key: "", value: "" });
      setNotification({ type: "success", content: t("prompts.addSuccess") });
    } catch (error) {
      setNotification({ type: "error", content: t("prompts.addError", { message: error.message }) });
    } finally {
      setSaveInProgress(false);
    }
  };

  const handleEditPrompt = async () => {
    try {
      setSaveInProgress(true);
      if (!selectedPrompt.value) {
        setNotification({ type: "error", content: t("prompts.emptyContent") });
        return;
      }

      await updatePrompt(templateId, selectedPrompt.key, selectedPrompt.value);
      
      const updatedPrompts = prompts.map(p => 
        p.key === selectedPrompt.key ? selectedPrompt : p
      );
      
      setPrompts(updatedPrompts);
      setShowEditModal(false);
      setNotification({ type: "success", content: t("prompts.updateSuccess") });
    } catch (error) {
      setNotification({ type: "error", content: t("prompts.updateError", { message: error.message }) });
    } finally {
      setSaveInProgress(false);
    }
  };

  const handleDeletePrompt = async () => {
    try {
      setSaveInProgress(true);
      
      await deletePrompt(templateId, selectedPrompt.key);
      
      const updatedPrompts = prompts.filter(p => p.key !== selectedPrompt.key);
      setPrompts(updatedPrompts);
      setShowDeleteModal(false);
      setNotification({ type: "success", content: t("prompts.deleteSuccess") });
    } catch (error) {
      setNotification({ type: "error", content: t("prompts.deleteError", { message: error.message }) });
    } finally {
      setSaveInProgress(false);
    }
  };

  const getKeyLabel = () => {
    if (templateId === "LLMPromptSummaryKeyValue") {
      return t("prompts.promptKey");
    }
    return t("prompts.promptId");
  };

  const getValueLabel = () => {
    if (templateId === "LLMPromptSummaryKeyValue") {
      return t("prompts.promptText");
    }
    return t("prompts.promptContent");
  };

  const sortPrompts = (prompts) => {
    return [...prompts].sort((promptA, promptB) => {
      const keyA = promptA.key;
      const keyB = promptB.key;
      
      const hasFormatA = keyA.includes("#");
      const hasFormatB = keyB.includes("#");
      
      if (hasFormatA && hasFormatB) {
        const numA = parseInt(keyA.split("#")[0], 10) || 0;
        const numB = parseInt(keyB.split("#")[0], 10) || 0;
        return numB - numA;
      }
      
      if (hasFormatA && !hasFormatB) return -1;
      if (!hasFormatA && hasFormatB) return 1;
      
      return keyA.localeCompare(keyB);
    });
  };

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description={templateId}
        >
          {t("prompts.editor")}
        </Header>
      }
    >
      {notification && (
        <Alert
          type={notification.type}
          dismissible
          onDismiss={() => setNotification(null)}
        >
          {notification.content}
        </Alert>
      )}

      <Container>
        {loading ? (
          <Box textAlign="center" padding={{ bottom: "l" }}>
            <Spinner size="large" />
          </Box>
        ) : (
          <Table
            items={sortPrompts(prompts)}
            columnDefinitions={[
              {
                id: "key",
                header: getKeyLabel(),
                cell: item => item.key,
                sortingField: "key"
              },
              {
                id: "value",
                header: getValueLabel(),
                cell: item => {
                  if (isKeyValueTemplate) {
                    return item.value;
                  }
                  return item.value.length > 100 
                    ? `${item.value.substring(0, 100)}...` 
                    : item.value;
                }
              },
              {
                id: "actions",
                header: t("prompts.actions"),
                cell: item => (
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button 
                      iconName="edit" 
                      variant="icon" 
                      onClick={() => {
                        setSelectedPrompt(item);
                        setShowEditModal(true);
                      }}
                      ariaLabel={`Editar ${item.key}`}
                    />
                    <Button 
                      iconName="remove" 
                      variant="icon" 
                      onClick={() => {
                        setSelectedPrompt(item);
                        setShowDeleteModal(true);
                      }}
                      ariaLabel={`Eliminar ${item.key}`}
                    />
                  </SpaceBetween>
                )
              }
            ]}
            loading={loading}
            sortingDisabled={false}
            header={
              <Header
                counter={`(${prompts.length})`}
                variant="h2"
                actions={
                  <Button onClick={() => setShowAddModal(true)}>{t("prompts.addPrompt")}</Button>
                }
              >
                {templateId}
              </Header>
            }
            empty={
              <Box textAlign="center" color="inherit">
                <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                  {t("prompts.emptyPrompts")}
                </Box>
                <Button onClick={() => setShowAddModal(true)}>{t("prompts.addPrompt")}</Button>
              </Box>
            }
          />
        )}
      </Container>

      <Modal
        visible={showAddModal}
        onDismiss={() => setShowAddModal(false)}
        header={templateId}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => setShowAddModal(false)} variant="link">{t("confirmModal.cancel")}</Button>
              <Button onClick={handleAddPrompt} variant="primary" loading={saveInProgress}>{t("confirmModal.add")}</Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField
            label={getKeyLabel()}
            description={isKeyValueTemplate 
              ? t("prompts.keyValue")
              : t("prompts.promptFormat")
            }
          >
            <Input
              value={newPrompt.key}
              onChange={({detail}) => setNewPrompt({...newPrompt, key: detail.value})}
              placeholder={isKeyValueTemplate ? t("prompts.exampleFormat") : t("prompts.examplePromptFormat")}
            />
          </FormField>

          <FormField
            label={getValueLabel()}
            description={isKeyValueTemplate 
              ? t("prompts.keyValueText")
              : t("prompts.promptTextPlaceholder")
            }
          >
            {isKeyValueTemplate ? (
              <Input
                value={newPrompt.value}
                onChange={({detail}) => setNewPrompt({...newPrompt, value: detail.value})}
                placeholder={t("prompts.translationExample")}
              />
            ) : (
              <Textarea
                value={newPrompt.value}
                onChange={({detail}) => setNewPrompt({...newPrompt, value: detail.value})}
                rows={10}
                placeholder={t("prompts.promptPlaceholder")}
              />
            )}
          </FormField>
        </SpaceBetween>
      </Modal>

      <Modal
        visible={showEditModal && selectedPrompt}
        onDismiss={() => setShowEditModal(false)}
        header={t("prompts.editPromptTitle", { key: selectedPrompt?.key })}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => setShowEditModal(false)} variant="link">{t("confirmModal.cancel")}</Button>
              <Button onClick={handleEditPrompt} variant="primary" loading={saveInProgress}>{t("confirmModal.save")}</Button>
            </SpaceBetween>
          </Box>
        }
      >
        {selectedPrompt && (
          <SpaceBetween direction="vertical" size="l">
            <FormField
              label={getValueLabel()}
            >
              {isKeyValueTemplate ? (
                <Input
                  value={selectedPrompt.value}
                  onChange={({detail}) => setSelectedPrompt({...selectedPrompt, value: detail.value})}
                />
              ) : (
                <Textarea
                  value={selectedPrompt.value}
                  onChange={({detail}) => setSelectedPrompt({...selectedPrompt, value: detail.value})}
                  rows={15}
                />
              )}
            </FormField>
          </SpaceBetween>
        )}
      </Modal>

      <Modal
        visible={showDeleteModal && selectedPrompt}
        onDismiss={() => setShowDeleteModal(false)}
        header={t("confirmModal.title")}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => setShowDeleteModal(false)} variant="link">{t("confirmModal.cancel")}</Button>
              <Button onClick={handleDeletePrompt} variant="primary" loading={saveInProgress}>{t("confirmModal.delete")}</Button>
            </SpaceBetween>
          </Box>
        }
      >
        <p>{t("confirmModal.messageDelete")} <strong>{selectedPrompt?.key}</strong></p>
      </Modal>
    </ContentLayout>
  );
}

export default PromptEditor;