import React, { createContext, useContext, useState, useCallback } from 'react';

// Crear el contexto
const AlertContext = createContext();

// Proveedor del contexto
export const AlertProvider = ({ children }) => {
  const [alert, setAlertState] = useState(null);
  
  const dismissAlert = useCallback(() => {
    setAlertState(null);
  }, []);
  
  // Función principal para mostrar alertas
  const setAlert = useCallback((alertData) => {
    if (!alertData) {
      setAlertState(null);
      return;
    }
    
    setAlertState(alertData);
    
    // Si se especificó autoDismiss, configurar un temporizador
    if (alertData.autoDismiss !== false) {
      const timeout = alertData.timeout || 5000;
      setTimeout(dismissAlert, timeout);
    }
  }, [dismissAlert]);
  
  // Funciones auxiliares para tipos comunes de alertas
  const showSuccess = useCallback((text, heading = 'Éxito', options = {}) => {
    setAlert({
      variant: 'success',
      heading,
      text,
      ...options
    });
  }, [setAlert]);
  
  const showError = useCallback((text, heading = 'Error', options = {}) => {
    setAlert({
      variant: 'error',
      heading,
      text,
      ...options
    });
  }, [setAlert]);
  
  const showWarning = useCallback((text, heading = 'Advertencia', options = {}) => {
    setAlert({
      variant: 'warning',
      heading,
      text,
      ...options
    });
  }, [setAlert]);
  
  const showInfo = useCallback((text, heading = 'Información', options = {}) => {
    setAlert({
      variant: 'info',
      heading,
      text,
      ...options
    });
  }, [setAlert]);

  return (
    <AlertContext.Provider 
      value={{ 
        alert, 
        setAlert, 
        dismissAlert,
        showSuccess,
        showError,
        showWarning,
        showInfo
      }}
      style={{ marginBottom: '30rem' }}
    >
      {children}
    </AlertContext.Provider>
  );
};

// Hook para facilitar el uso del contexto
export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert debe ser usado dentro de un AlertProvider');
  }
  return context;
};

export default AlertContext;