import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Header,
  SpaceBetween,
  Alert,
  TextContent,
} from '@cloudscape-design/components';
import { useTranslation } from 'react-i18next';

const AccessDenied = () => {
  const history = useHistory();
  const { t } = useTranslation();
  
  const handleNavigateHome = () => {
    history.push('/');
  };

  return (
    <Box padding="l" textAlign="center">
      <Container 
        header={
          <Header variant="h1">
            {t("accessDenied.title")}
          </Header>
        }
      >
        <SpaceBetween size="l">
          <Alert
            type="error"
            header={t("accessDenied.alertHeader")}
          >
            <TextContent>
              <p>{t("accessDenied.alertText")}</p>
            </TextContent>
          </Alert>
          
          <Box margin="xxl" textAlign="center">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="primary" onClick={handleNavigateHome}>
                {t("accessDenied.backHome")}
              </Button>
              <Button variant="link" onClick={() => window.history.back()}>
                {t("accessDenied.back")}
              </Button>
            </SpaceBetween>
          </Box>
        </SpaceBetween>
      </Container>
    </Box>
  );
};

export default AccessDenied;