import React, { createContext, useContext, useState, useEffect } from "react";
import { getPermissions } from "./api";

const UserRoleContext = createContext();

const fetchPermissions = async () => {
  try {
    const response = await getPermissions();
    return {
      role: response.role,
      permissions: response.permissions
    };
  } catch (error) {
    console.error("Error al obtener permisos:", error);
  }
};

export const UserRoleProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadRoleAndPermissions = async () => {
      try {
        setIsLoading(true);
        const { role, permissions } = await fetchPermissions();
        setUserRole(role);
        setPermissions(permissions);
      } catch (error) {
        console.error("Error cargando permisos:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    loadRoleAndPermissions();
  }, []);
  
  const hasPermission = (permission) => {
    if (isLoading || !permissions) return false;
    return permissions.includes(permission);
  };
  
  return (
    <UserRoleContext.Provider value={{ 
      userRole,
      roleName: userRole ? userRole : "Invitado",
      permissions,
      hasPermission,
      isLoading
    }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => useContext(UserRoleContext);