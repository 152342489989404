import React, { useEffect, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { Table, TextFilter, Pagination, CollectionPreferences, PropertyFilter, Modal, Box, SpaceBetween, Alert, Flashbar } from '@cloudscape-design/components';
import { useHistory } from "react-router-dom";
import { Formatter } from "../format";
import { Placeholder } from "./Placeholder";
import { SentimentIcon } from "./SentimentIcon";
import { TrendIcon } from "./TrendIcon";
import "./ContactTable.css";
import { DateTimeForm, formatDateTime } from './DateTimeForm';
import { ContactTablePreferences, DEFAULT_PREFERENCES } from './ContactTablePreferences'
import { useLocalStorage } from '../common/localStorage';
import Popover from "@cloudscape-design/components/popover";
import Button from "@cloudscape-design/components/button";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import Icon from "@cloudscape-design/components/icon";
import Link from "@cloudscape-design/components/link";
import { useTranslation } from 'react-i18next';
import "../styles/CustomPopover.css";
import { getpromptskeyvalue } from "../api/api";
import { deleteAudio } from "../api/api";
import { useUserRole } from "../api/role";

const COLUMN_DEFINITIONS = [
  {
    id: "timestamp",
    header: "Timestamp",
    sortingField: "timestamp",
    cell: (d) => {
      if(d.status !== undefined && d.status.length > 0 && d.status !== "Done" && d.status !== "Rechazado") {
        return (
            Formatter.Timestamp(d.timestamp)
        )
      }
      else {
        return (
            <Link href={`/dashboard/${d.key}`}>{Formatter.Timestamp(d.timestamp)}</Link>
        )
      }
    },
    minWidth: 160
  },
  {
    id: "jobName",
    header: "Job Name",
    cell: (d) => {
      if(d.status !== undefined && d.status.length > 0 && d.status !== "Done" && d.status !== "Rechazado") {
        return (
            d.jobName
        )
      }
      else {
        return (
            <Link variant="primary" href={`/dashboard/${d.key}`}>{d.jobName}</Link>
        )
      }
    },
    isRowHeader: true,
    sortingField: "jobName",
  },
  {
    id: "status",
    header: "Status",
    cell: (d) => d.status,
    isRowHeader: true,
    sortingField: "status",
    minWidth:130
  },
  {
    id: "guid",
    header: "GUID",
    cell: (d) => d.guid,
    isRowHeader: true,
    sortingField: "guid",
    minWidth:150
  },
  {
    id: "agent",
    header: "Agent",
    cell: (d) => d.agent,
    isRowHeader: true,
    sortingField: "agent",
    minWidth:130
  },
  {
    id: "customer",
    header: "Customer",
    cell: (d) => d.customer,
    isRowHeader: true,
    sortingField: "customer",
    minWidth:130
  },
  {
    id: "queue",
    header: "Queue",
    cell: (d) => d.queue,
    isRowHeader: true,
    sortingField: "queue",
    minWidth:130
  },
  {
    id: "callerSentimentScore",
    header: "Cust Sent",
    sortingField: "callerSentimentScore",
    cell: (d) => (
      <div className="d-flex justify-content-evenly">
        <SentimentIcon score={d?.callerSentimentScore} />
        <TrendIcon trend={d.callerSentimentChange} />
      </div>
    ),
    minWidth: 130
  },
  {
    id: "langCode",
    header: <div className="col-header-wrapper text-left">Lang Code</div>,
    cell: (d) => d.lang,
    minWidth: 100,
  },
  {
    id: "duration",
    header: <div className="col-header-wrapper text-left">Duration</div>,
    cell: (d) => Formatter.Time(d.duration),
    minWidth: 130,
  },
  {
    id: "actions",
    header: "Actions",
    minWidth: 50,
  }
];

const getMatchesCountText = function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

const Loading = () =>
  COLUMN_DEFINITIONS.map((c, i) => (
    <td key={i}>
      <Placeholder />
    </td>
  ));

const NoMatches = ({ children }) => (
  <tr>
    <td colSpan={COLUMN_DEFINITIONS.length}>
      <div className="d-flex justify-content-center py-4">{children}</div>
    </td>
  </tr>
);

const getPromptsKeyValue = async () => {
  const response = await getpromptskeyvalue();
  return response;
};

const useTranslatedColumnDefinitions = (promptsKeyValue) => {
  const { t } = useTranslation();
  
  return COLUMN_DEFINITIONS.map(column => ({
    ...column,
    header: t(`contactTable.${column.id}`),
    cell: column.cell
  }));
};

export const ContactTable = ({ data = [], loading = false, empty, header, variant='embedded', pagination, onShowAlertBannerChange }) => {
  const history = useHistory();
  const { hasPermission } = useUserRole();
  const [promptsKeyValue, setPromptsKeyValue] = useState({});

  // useEffect(() => {
  //   const fetchPromptsKeyValue = async () => {
  //     const response = await getPromptsKeyValue();
  //     setPromptsKeyValue(response);
  //   };
  //   fetchPromptsKeyValue();
  // }, []);

  const summaryColumns = Object.keys(promptsKeyValue).map((key) => ({
    id: key,
    header: promptsKeyValue[key],
    cell: (d) => d[`summary_${key}`] || d[`voc_summary_${key}`],
    isRowHeader: true,
    minWidth: 130,
  }));


  const { t } = useTranslation();
  const translatedColumnDefinitions = useTranslatedColumnDefinitions(promptsKeyValue);

  const [preferences, setPreferences] = useLocalStorage(
    'contact-table-preferences',
    DEFAULT_PREFERENCES,
  );

  useEffect(() => {
    if (onShowAlertBannerChange) {
      console.log(preferences.custom?.showAlertBanner)
      onShowAlertBannerChange(preferences.custom?.showAlertBanner ?? true);
    }
  }, [preferences.custom?.showAlertBanner, onShowAlertBannerChange]);

  const onClick = (e) => {
    console.log(e);
    history.push(`/dashboard/${e.detail.item.key}`);
  };

  const [
    callQuery,
    setCallQuery
  ] = React.useState({
    tokens: [],
    operation: "and"
  });

  const summaryFields = Object.keys(promptsKeyValue).map((key) => ({
    key: `summary_${key}`,
    operators: ["=", "!=", ":", "!:"],
    propertyLabel: promptsKeyValue[key],
    groupValuesLabel: promptsKeyValue[key],
  }));

  const vocSummaryFields = Object.keys(promptsKeyValue).map((key) => ({
    key: `voc_summary_${key}`,
    operators: ["=", "!=", ":", "!:"],
    propertyLabel: promptsKeyValue[key],
    groupValuesLabel: promptsKeyValue[key],
  }));

  const [localData, setLocalData] = useState(data);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteAudio(selectedItem.key);
      setLocalData(prevData => prevData.filter(i => i.key !== selectedItem.key));
      setDeleteSuccess(true);
    } catch (err) {
      setDeleteError("Error: " + err.message);
    } finally {
      setIsDeleting(false);
      setShowModal(false);
    }
  };

  const columnDefinitionsWithActions = translatedColumnDefinitions.map(column => {
    if (column.id === 'actions') {
      return {
        ...column,
        cell: (item) => (
          <div style={{ display: 'flex' }}>
            <Button 
              iconName="remove" 
              variant="icon"
              disabled={!hasPermission("manage_audios")}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedItem(item);
                setShowModal(true);
              }} 
            />
          </div>
        ),
      };
    }
    return column;
  });

  const successMessage = [{
    type: "success",
    content: t("contactTable.deleteSuccess"),
    dismissible: true,
    onDismiss: () => { setDeleteSuccess(false); },
    id: "message_delete"
  }];

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
    localData,
    {
      propertyFiltering: {
        filteringProperties: [
          {
            key: "timestamp",
            defaultOperator: '>',
            operators: ['<', '<=', '>', '>='].map(operator => ({
              operator,
              form: DateTimeForm,
              format: formatDateTime,
              match: 'datetime',
            })),
            propertyLabel: t("contactTable.timestamp"),
            groupValuesLabel: "Timestamps"
          },
          {
            key: "jobName",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: t("contactTable.jobName"),
            groupValuesLabel: "Job Names"
          },
          {
            key: "guid",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "GUID",
            groupValuesLabel: "GUIDs"
          },
          {
            key: "agent",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: t("contactTable.agent"),
            groupValuesLabel: "Agents"
          },
          {
            key: "customer",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: t("contactTable.customer"),
            groupValuesLabel: "Customers"
          },
          {
            key: "queue",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: t("contactTable.queue"),
            groupValuesLabel: "Queues"
          },
          {
            key: "lang",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: t("contactTable.langCode"),
            groupValuesLabel: "Languages Codes"
          },
          {
            key: "duration",
            defaultOperator: '>',
            operators: ['<', '<=', '>', '>='],
            propertyLabel: t("contactTable.duration"),
            groupValuesLabel: "Durations"
          },
        ],
        empty: (
          <div>No Calls.</div>
        ),
        noMatch: (
          <div>No matches.</div>
        )
      },
      pagination: { pageSize: 100000000 },
      sorting: {},
      selection: {},
    });

  return (
    <>
      {deleteSuccess && <Flashbar items={successMessage} />}
      {deleteError !== "" && (
        <Alert dismissible onDismiss={() => { setDeleteError(""); }} type="error">
          {deleteError}
        </Alert>
      )}
      <Table
        {...collectionProps}
        header={header}
        variant={variant}
        columnDefinitions={columnDefinitionsWithActions}
        columnDisplay={preferences.contentDisplay}
        items={items}
        pagination={pagination && (
          <Pagination
            currentPageIndex={pagination.currentPageIndex}
            pagesCount={pagination.pagesCount}
            onChange={({ detail }) => {
              pagination.onPageChange({ 
                detail: { currentPageIndex: parseInt(detail.currentPageIndex, 10) } 
              });
            }}
          />
        )}
        resizableColumns={true}
        loadingText="Loading Calls"
        // onSelectionChange={onClick}
        // onRowClick={onClick}
        // selectionType="single"
        stickyHeader={true}
        stickyColumns={{ first: 2, last: 0 }}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            /*onChange={({ detail }) => {
              console.log(detail);
              //setCallQuery(detail);
            }}*/
            //query={callQuery}
            i18nStrings={{
              filteringAriaLabel: t("contactTable.filteringAriaLabel"),
              dismissAriaLabel: t("contactTable.dismissAriaLabel"),
              filteringPlaceholder: t("contactTable.filteringPlaceholder"),
              groupValuesText: t("contactTable.groupValuesText"),
              groupPropertiesText: t("contactTable.groupPropertiesText"),
              operatorsText: t("contactTable.operatorsText"),
              operationAndText: t("contactTable.operationAndText"),
              operationOrText: t("contactTable.operationOrText"),
              operatorLessText: t("contactTable.operatorLessText"),
              operatorLessOrEqualText: t("contactTable.operatorLessOrEqualText"),
              operatorGreaterText: t("contactTable.operatorGreaterText"),
              operatorGreaterOrEqualText: t("contactTable.operatorGreaterOrEqualText"),
              operatorContainsText: t("contactTable.operatorContainsText"),
              operatorDoesNotContainText: t("contactTable.operatorDoesNotContainText"),
              operatorEqualsText: t("contactTable.operatorEqualsText"),
              operatorDoesNotEqualText: t("contactTable.operatorDoesNotEqualText"),
              editTokenHeader: t("contactTable.editTokenHeader"),
              propertyText: t("contactTable.propertyText"),
              operatorText: t("contactTable.operatorText"),
              valueText: t("contactTable.valueText"),
              cancelActionText: t("contactTable.cancelActionText"),
              applyActionText: t("contactTable.applyActionText"),
              allPropertiesLabel: t("contactTable.allPropertiesLabel"),
              tokenLimitShowMore: t("contactTable.tokenLimitShowMore"),
              tokenLimitShowFewer: t("contactTable.tokenLimitShowFewer"),
              clearFiltersText: t("contactTable.clearFiltersText"),
              removeTokenButtonAriaLabel: token =>
                t("contactTable.removeTokenButtonAriaLabel", { propertyKey: token.propertyKey, operator: token.operator, value: token.value }),
              enteredTextLabel: text => t("contactTable.enteredTextLabel", { text })
            }}
            countText={getMatchesCountText(filteredItemsCount)}
            expandToViewport={true}
          />
        }

        preferences={
          <ContactTablePreferences preferences={preferences} setPreferences={setPreferences} promptsKeyValue={promptsKeyValue}/>
        }
        visibleColumns={['jobName', ...preferences.visibleContent, 'actions']}
      />
      {showModal && (
        <Modal
          visible={showModal}
          onDismiss={() => setShowModal(false)}
          header={t("confirmModal.title")}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => setShowModal(false)} variant="link">{t("confirmModal.cancel")}</Button>
                <Button 
                  onClick={handleDelete} 
                  loading={isDeleting} 
                  variant="primary"
                >
                  {t("confirmModal.confirm")}
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <p>{t("confirmModal.message")}</p>
        </Modal>
      )}
    </>
  );
};