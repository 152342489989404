import { Flashbar, Alert } from "@cloudscape-design/components";

const AlertNotification = ({ alert, clearAlert, t }) => {
    if (!alert) return null;

    if (alert.type === "error") {
        return (
            <Alert dismissible onDismiss={clearAlert} type="error">
                {alert.message}
            </Alert>
        );
    }
    
    if (alert.type === "success") {
        return (
            <div style={{ marginBottom: "10px" }}>
                <Flashbar items={[{
                    type: "success",
                    content: alert.message,
                    dismissible: true,
                    onDismiss: clearAlert,
                    id: "alert_success"
                }]} />
            </div>
        );
    }
    return null;
};
  
export default AlertNotification;