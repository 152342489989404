import React, { useState, useEffect, useRef } from "react";
import {
  ContentLayout,
  Container,
  Header,
  Box,
  Spinner,
  Alert,
  SpaceBetween
} from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { useAlert } from "../components/Alert";
import { getQuickSightEmbedUrl } from "../api/api";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";

const Insights = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { showError } = useAlert();
  const { t } = useTranslation();
  
  const containerRef = useRef(null);

  useEffect(() => {
    const loadQuickSightDashboard = async () => {
      try {
        setError(null);
        
        const response = await getQuickSightEmbedUrl();
        if (!response || !response.embeddedUrl) {
          throw new Error(t("dashboard.loadError"));
        }
        
        if (!containerRef.current) {
          throw new Error("El contenedor para el dashboard no se encontró.");
        }
        
        const embeddingContext = await QuickSightEmbedding.createEmbeddingContext();
        embeddingContext.embedDashboard({
          url: response.embeddedUrl,
          container: containerRef.current,
          height: "700px",
          width: "100%"
        });
      } catch (error) {
        console.error("Error al cargar el dashboard:", error);
        setError(error.message);
        showError(t("dashboard.loadError"));
      } finally {
        setLoading(false);
      }
    };

    if (containerRef.current) {
      loadQuickSightDashboard();
    }
  }, [showError, t]);

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description={t("dashboard.description")}
        >
          {t("dashboard.title")}
        </Header>
      }
    >
      {error && (
        <Alert type="error" dismissible onDismiss={() => setError(null)}>
          {error}
        </Alert>
      )}

      <Container>
        <SpaceBetween size="l">
          {loading && (
            <Box textAlign="center" padding={{ bottom: "l" }}>
              <Spinner size="large" />
              <Box padding={{ top: "s" }} color="text-status-info">
                {t("dashboard.loading")}
              </Box>
            </Box>
          )}
          <Box padding="s">
            <div
              ref={containerRef}
              id="quicksight-container"
              style={{ minHeight: "700px", width: "100%" }}
            ></div>
          </Box>
        </SpaceBetween>
      </Container>
    </ContentLayout>
  );
};

export default Insights;
