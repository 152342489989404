import React from "react";
import { useState, useEffect, useRef } from "react";
import useSWR from "swr";
import { list } from "../api/api";
import { ContactTable } from "../components/ContactTable";
import { Upload } from "../components/Upload";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import { useHistory } from 'react-router-dom';
import { AlertBanner } from "../components/AlertBanner";
import { DashboardMetrics } from "../components/DashboardMetrics";
import { useDangerAlert } from "../hooks/useAlert";
import { presign } from "../api/api";
import { useUploadProgress } from '../hooks/UploadProgressContext';

import {
  Button,
  ContentLayout,
  Link,
  Header,
  BreadcrumbGroup,
  Grid,
  FileUpload,
  FormField,
  Form,
  SpaceBetween,
  Spinner,
  ColumnLayout,
  Container,
  ProgressBar,
  StatusIndicator,
  Box
} from '@cloudscape-design/components';

import { useTranslation } from 'react-i18next';
import "../locales/i18n";
import { useUserRole } from "../api/role";

const config = window.pcaSettings;

function Home({ setAlert }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageKeys, setPageKeys] = useState([]);
  const [showAlertBanner, setShowAlertBanner] = useState(true);
  const { checkCompletedFiles, calculateOverallProgress, showProcessingProgress, uploadItems, progressData, setIsModalVisible } = useUploadProgress();

  const PAGE_SIZE = 20;
  const { hasPermission } = useUserRole();
  const fetcher = async (page) => {
    const opts = {
      count: PAGE_SIZE
    };
    
    if (page > 1) {
      const previousPageKey = pageKeys[page - 2];
      if (previousPageKey) {
        opts.startKey = previousPageKey.StartKey;
        opts.timestampFrom = previousPageKey.timestampFrom;
      }
    }
    
    const response = await list(opts);

    if (response.StartKey && response.timestampFrom) {
      setPageKeys(prev => {
        const newKeys = [...prev];
        newKeys[page - 1] = {
          StartKey: response.StartKey,
          timestampFrom: response.timestampFrom
        };
        return newKeys;
      });
    }

    return response;
  };

  const { data, error } = useSWR(currentPage, fetcher);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (data) {
      if (data.StartKey && currentPage >= totalPages) {
        setTotalPages(prev => prev + 1);
      }
  
      // Primero verificar archivos completados
      const filesCompleted = checkCompletedFiles(data.Records);
      
      // Solo buscar nuevos archivos en procesamiento si no hay mostrados
      // o si algunos fueron completados (para actualizar la lista)
      const shouldCheckProcessing = !processingFilesShown.current || filesCompleted;
  
      if (shouldCheckProcessing) {
        const processingFiles = data.Records.filter(record => 
          ['Transcribing', 'transcribing', 'processing', 'En progreso'].includes(record.status)
        );
        
        // Filtrar solo los que no están ya completados
        const activeProcessingFiles = processingFiles.filter(record => {
          const fileName = record.jobName || record.guid;
          return !progressData[fileName] || progressData[fileName].status !== 'completed';
        });
  
        if (activeProcessingFiles.length > 0) {          
          const uploadItems = activeProcessingFiles.map(record => ({
            label: record.jobName || record.guid,
            fileId: record.key,
            file: { name: record.jobName || record.guid }
          }));
          
          const newProgressData = {};
          activeProcessingFiles.forEach(record => {
            const fileName = record.jobName || record.guid;
            newProgressData[fileName] = {
              status: 'processing',
              progress: record.status === 'Transcribing' ? 60 : 50,
              error: ''
            };
          });
          
          showProcessingProgress(uploadItems, newProgressData);
          processingFilesShown.current = true;
        } else if (filesCompleted && processingFilesShown.current) {
          // Si no hay archivos en procesamiento y estábamos mostrando alguno
          // (y acabamos de completar alguno), resetear el estado
          processingFilesShown.current = false;
        }
      }
    }
  }, [data, currentPage, checkCompletedFiles]);

  const processingFilesShown = useRef(false);

  const handlePageChange = ({ detail }) => {
    const newPage = detail.currentPageIndex;
    setCurrentPage(newPage);
  };

  const { t } = useTranslation();

  const isEmpty = data?.[0]?.length === 0;

  const details = data ? data.Records : [];
  useDangerAlert(error, setAlert);
  
  return (
    <>
      <ContentLayout 
        header={
            <Header
              variant="h1"
              description={t('home.description')}
              info={<Link variant="info" ariaLabel="Info goes here.">Info</Link>}>
              {t('callList')}
            </Header>
        }>
        <Container>
          <Grid
            gridDefinition={[
              {colspan: { default:12} },
              {colspan: { default:12} },
              {colspan: { default:12} },
              {colspan: { default:12} }
            ]}
          >
            {hasPermission("view_dashboard_metrics") && (
              <DashboardMetrics data={data} />
            )}
            {showAlertBanner && <AlertBanner />}
            {hasPermission("manage_audios") && (
              <ExpandableSection headerText={t("home.uploadCall")}>
                <Upload/>
            </ExpandableSection>
            )}
            {uploadItems && uploadItems.length > 0 && (
              <Container header={<Header variant="h2">{t('upload.progress', 'Progreso de archivos')}</Header>}>
                <Box padding="m">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <div style={{ flexGrow: 1 }}>
                      <ProgressBar
                        value={calculateOverallProgress()}
                        label={`${calculateOverallProgress()}%`}
                        description={`${uploadItems.length} ${t('upload.files', 'archivos')}`}
                      />
                    </div>
                    <Button 
                      onClick={() => setIsModalVisible(true)}
                      iconName="external"
                      variant="link"
                      formAction="none"
                    >
                      {t('upload.viewDetails', 'Ver detalles')}
                    </Button>
                  </div>
                </Box>
              </Container>
            )}
            
            <ContactTable
              data={details}
              loading={!data && !error}
              empty={<Empty />}
              pagination={{
                currentPageIndex: currentPage,
                pagesCount: totalPages,
                onPageChange: handlePageChange
              }}
              onShowAlertBannerChange={setShowAlertBanner}
            />
            {/* <Button
            variant="normal"
            onClick={() => history.push('/analytics')}
          >
            {t("analytics.header")}
          </Button> */}
          </Grid>
        </Container>
        
      </ContentLayout>
      </>
  );
}

const Empty = () => (
  <div>
    <h2>No results</h2>
  </div>
);

export default Home;